import {getNameFromCookiesOrGenerate} from "@/helpers/names-generation";

export function getCookie(name: string): string | null {
    const match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
    return match ? decodeURIComponent(match[2]) : null;
}

export function setCookie(name: string, value: string, days: number): void {
    const date = new Date();
    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
    const expires = "expires=" + date.toUTCString();
    document.cookie = `${name}=${encodeURIComponent(value)};${expires};path=/`;
}

export function deleteCookie(name: string): void {
    setCookie(name, '', -1);
}

export async function initializeCookies(): Promise<void> {
    setCookie('previousUrl', window.location.href, 365);
    const username = await getNameFromCookiesOrGenerate();
    let visitCount = Number(getCookie('visitCount')) || 0;
    visitCount += 1; // Увеличиваем счетчик посещений
    if(getHourDiff() >= 2) {
        setCookie('visitCount', visitCount.toString(), 365);
        setCookie('lastVisit', new Date().toISOString(), 365);
    }
    // console.log(`User ID: ${username}, Visit Count: ${visitCount}, Last Visit: ${new Date().toISOString()}`);
}

export function getHourDiff() {
    const lastVisitCookie = getCookie('lastVisit');
    const lastVisitDate = lastVisitCookie ? new Date(lastVisitCookie) : new Date();
    const now = new Date();
    const timeDiff = now.getTime() - lastVisitDate.getTime();
    // const dayDiff = timeDiff / (1000 * 3600 * 24);
    // console.log('dayDiff', dayDiff)
    // return dayDiff;
    const hourDiff = timeDiff / (1000 * 3600); // Преобразование миллисекунд в часы
    // console.log(`Hour Difference: ${hourDiff}`);
    return hourDiff;
}