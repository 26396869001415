import cn from 'classnames';
import { DetailedHTMLProps, HTMLAttributes, ReactNode } from 'react';

export interface PProps extends DetailedHTMLProps<HTMLAttributes<HTMLParagraphElement>, HTMLParagraphElement> {
	size?: 's' | 'm' | 'l' | 'g';
	children: ReactNode;
}

export const P = ({ size = 'm', children, className, ...props }: PProps): JSX.Element => {
	return (
		<p
			data-metrica="content"
			itemProp="text"
			className={cn({
				['text-lg font-light text-text-secondary leading-6']: size === 's',
				['text-lg font-light text-text-secondary leading-8 xl:text-xl']: size === 'm',
				['font-semibold text-text-secondary leading-8 text-xl md:text-xl']: size === 'l',
				['text-3xl font-light text-secondary my-3']: size === 'g',
			}, className)}
			{...props}
		>
			{children}
		</p>
	);
};
