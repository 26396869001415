import {getCookie, setCookie} from "@/helpers/cookies";

function generateUniqueName(): string {
    const now = new Date();
    const day = String(now.getDate()).padStart(2, '0');
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const timestamp = `${day}${hours}${minutes}`;
    const randomNumber = Math.floor(100000 + Math.random() * 900000);
    return `${'user'}-${timestamp}-${randomNumber}`;
}

export async function getNameFromCookiesOrGenerate(): Promise<string> {
    const cookieLogin = 'login';
    let login = await getCookie(cookieLogin);
    if (!login) {
        login = generateUniqueName();
        await setCookie(cookieLogin, login, 365); // Сохраняем cookie на 1 год
    }
    return login;
}
