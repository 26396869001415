import cn from 'classnames';
import { DetailedHTMLProps, HTMLAttributes, ReactNode } from 'react';

export interface IconProps extends DetailedHTMLProps<HTMLAttributes<HTMLParagraphElement>, HTMLParagraphElement> {
	size?: 's' | 'm' | 'l' | 'logo';
    path: string;
    alt: string;
}
export const Icon = ({ size = 'm', path, className, alt }: IconProps) => {
	return (
		<img
			className={cn(className, {
				['h-3 w-3']: size == 's',
				['h-20 w-20']: size == 'm',
				['h-10 w-10']: size == 'l',
				['h-14 w-28']: size == 'logo'
			})}
            src={path} alt={alt} title={alt}
		/>
            
	);
};