import React from 'react';
import cn from 'classnames';

interface HtagProps {
	tag: 'h1' | 'h2' | 'h3';
	children: React.ReactNode;
	className?: string;
}

export const Htag = ({ tag, children, className }: HtagProps): JSX.Element => {
	const baseClass = cn(className, {
		'text-3xl font-semibold xl:text-6xl leading-tight xl:leading-tight': tag === 'h1',
		'text-2xl font-medium xl:text-4xl': tag === 'h2',
		'text-xl font-medium': tag === 'h3'
	});

	switch (tag) {
		case 'h1':
			return (
				<h1 className={baseClass} itemScope itemType="https://schema.org/Thing">
					<span itemProp="headline">{children}</span>
				</h1>
			);
		case 'h2':
			return (
				<h2 className={baseClass} itemScope itemType="https://schema.org/Thing">
					<span itemProp="alternativeHeadline">{children}</span>
				</h2>
			);
		case 'h3':
			return (
				<h3 className={baseClass} itemScope itemType="https://schema.org/Thing">
					<span itemProp="alternativeHeadline">{children}</span>
				</h3>
			);
		default:
			return <></>;
	}
};
