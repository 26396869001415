import React, {useState} from "react";
import cn from "classnames";
import {Form, FormControl, FormField, FormItem, FormLabel} from "@/components/ui/form";
import {Input} from "@/components/ui/input";
import {Button} from "@/components/ui/button";
import {z} from "zod";
import {useForm} from "react-hook-form";
import {zodResolver} from "@hookform/resolvers/zod";
import {postOrder} from "@/helpers/api";
import {useMask} from '@react-input/mask';
import {P} from "./ui/P";
import {sendGTMEvent} from "@next/third-parties/google";
import {popupData} from "@/data/popup-data";


export interface SimplePhoneForm {
    className: string;
}

export default function SimplePhoneForm({className}: SimplePhoneForm) {
    const [isSubmitted, setIsSubmitted] = useState(false);

    const formSchema = z.object({
        phone: z.string().nonempty({
            message: "Количество комнат"
        })
    });
    type FormDataProps = z.infer<typeof formSchema>;

    const form = useForm({
        resolver: zodResolver(formSchema),
        defaultValues: {
            phone: ""
        },
    });


    async function onSubmit(values: FormDataProps) {
        console.log("Submitting", values);
        try {
            const newValues = {...values, formname: "simple-phone-form"};
            const response = await postOrder(newValues);
            // @ts-ignore
            if (response.ok) {
                setIsSubmitted(true);
                sendGTMEvent({ event: 'send-simple-form', value: 'send-simple-form' })
                form.reset({
                    phone: ""
                });
            } else {
                throw new Error("Network response was not ok");
            }
        } catch (error) {
            console.error('Ошибка при отправке данных:', error);
        }
    };


    const inputRef = useMask(
        {mask: '+7 (___) ___-__-__', replacement: {_: /\d/}});

    if (isSubmitted) {
        return (
            <div className="text-center">
                <P size={'g'} className="text-2xl font-semibold">Данные отправлены</P>
                <P size={'s'} className="text-2xl font-semibold">Менеджер свяжется с вам в ближайшее время</P>
            </div>
        );
    }
    return (
        <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className={cn("space-y-8", className)}>
                <FormField
                    control={form.control}
                    name="phone"
                    render={({field}) => (
                        <FormItem>
                            <FormLabel>Номер телефона</FormLabel>
                            <FormControl ref={inputRef}>
                                <Input placeholder="+7(999)000-00-00" {...field} type="phone"/>
                            </FormControl>
                        </FormItem>
                    )}
                />
                <Button type="submit" size='lg' className="mt-8 hover:bg-secondary">Перезвоните мне</Button>
            </form>
        </Form>
    );
}
