import {RequestAnalyze, ResponseAnalyze} from "@/intefaces/api.props";
import {FormDataProps} from "@/intefaces/form-data.props";
import {getNameFromCookiesOrGenerate} from "@/helpers/names-generation";
import {RequestAds} from "@/app/(dashboard)/dashboard/address/page";

export async function getAnalyzeResult(request: RequestAnalyze
): Promise<ResponseAnalyze> {
    const apiUrl = `https://murmanclick.ru/api/analytic/v1.1?street=${encodeURIComponent(request.address)}&countRoom=${encodeURIComponent(request.roomType)}`;
    try {
        const response = await fetch(apiUrl);
        if (!response.ok) {
            throw new Error(`Сервер вернул ошибку с кодом ${response.status}`);
        }
        const data: ResponseAnalyze = await response.json();

        console.log('Data:', data);
        return data;
    } catch (error) {
        throw error; 
    }
}



export async function getAddressHistoryList(request: RequestAnalyze
): Promise<RequestAds []> {
    const apiUrl = `https://murmanclick.ru/api/address/history?street=${encodeURIComponent(request.address)}&countRoom=${encodeURIComponent(request.roomType)}`;
    try {
        const response = await fetch(apiUrl);
        if (!response.ok) {
            throw new Error(`Сервер вернул ошибку с кодом ${response.status}`);
        }
        const data: RequestAds [] = await response.json();
        console.log('Data:', data);
        return data;
    } catch (error) {
        throw error;
    }
}


export async function postOrder(request: FormDataProps) {
    try {
        const formData = getFormData(request);
        const response = await fetch("https://murmanclick.ru/back/order/webhook", {
            method: "POST",
            mode: "cors",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
            body: formData.toString(),
        });
        if (!response.ok) {
            throw new Error("Network response was not ok");
        }
        return response;
    } catch (error) {
        console.error('Ошибка при отправке данных на сервер', error);
    }
}

export async function setApiAddress() {
    try {
        const response = await fetch('https://murmanclick.ru/back/order/get-ip', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        });
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const data = await response.json();
        // console.log(data)
    } catch (error) {
        console.error('Error fetching IP:', error);
    }
}
export async function getFidList(){
    try {
        const response = await fetch('https://murmanclick.ru/api/ads/fid');
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const data = await response.json();
        console.log('Fetched data:', data);
        return data;
    } catch (err) {
        console.error(err);
    }
}
export async function togglePublishOnServer(id:number){
    try {
        const response = await fetch(`https://murmanclick.ru/api/ads/fid/${id}`);
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const data = await response.json();
        console.log('Fetched data:', data);
    } catch (err) {
        console.error(err);
    }
}


function getFormData(request: FormDataProps) {
    const formData = new URLSearchParams();
    const login = getNameFromCookiesOrGenerate();
    if(request.login) formData.append('login', login.toString());
    if (request.address) formData.append('address', request.address);
    if (request.phone) formData.append('phone', request.phone);
    if (request.name) formData.append('name', request.name);
    if (request.formname) formData.append('formname', request.formname);
    if (request.question) formData.append('question', request.question);
    formData.append('cookies', document.cookie);
    console.log("formData:", formData);
    return formData;
}

